import { FormTab, ImageField, ReferenceField, TextField, useShowController } from 'react-admin'
import React from 'react'

export const GuestDetailsTab = (props) => {
  const { record } = useShowController(props)

  return (
    <FormTab
      path='guest-details'
      label='Guest details'
      {...props}
    >
      {record.guestName ? <TextField source='guestName' label='Name' /> : null}
      {record.guestMobileNumber ? <TextField source='guestMobileNumber' label='Phone' /> : null}
      {record.delivererName ? <TextField source='delivererName' label='Deliverer name' /> : null}
      {record.delivererMobileNumber ? <TextField source='delivererMobileNumber' label='Deliverer Phone' /> : null}
      {record.visitorId && (
        <ReferenceField
          source='visitorId'
          label='Email'
          reference='mobileUsers'
          link={false}
        >
          <TextField source='email' />
        </ReferenceField>
      )}
      {record.visitorId && (
        <ReferenceField
          source='visitorId'
          label='Phone'
          reference='mobileUsers'
          link={false}
        >
          <TextField source='phone' />
        </ReferenceField>
      )}
      {record.emiratesData ? renderEmirateDataFields() : null}
    </FormTab>
  )
}

const renderEmirateDataFields = () => {
  return [
    <TextField
      label='Document ID'
      source='emiratesData.mrzData.document_number'
      key='emiratesData.mrzData.document_number'
    />,
    <TextField
      label='Name'
      source='emiratesData.mrzData.given_names_readable'
      key='emiratesData.mrzData.given_names_readable'
    />,
    <TextField
      label='Surname'
      source='emiratesData.mrzData.surname'
      key='emiratesData.mrzData.surname'
    />,
    <TextField
      label='Nationality'
      source='emiratesData.mrzData.nationality'
      key='emiratesData.mrzData.nationality'
    />,
    <TextField
      label='Gender'
      source='emiratesData.mrzData.sex'
      key='emiratesData.mrzData.sex'
    />,
    <TextField
      label='ID'
      source='emiratesData.mrzData.optionals'
      key='emiratesData.mrzData.optionals'
    />,
    <TextField
      label='Expiration Date'
      source='emiratesData.mrzData.expiration_date_readable'
      key='emiratesData.mrzData.expiration_date_readable'
    />,
    <TextField
      label='DOB'
      source='emiratesData.mrzData.dob_readable'
      key='emiratesData.mrzData.dob_readable'
    />,
    <ImageField
      source='emiratesData.mrzData.frontImage'
      key='emiratesData.mrzData.frontImage'
      label='Front'
    />,
    <ImageField
      source='emiratesData.mrzData.backImage'
      key='emiratesData.mrzData.backImage'
      label='Back'
    />,
    <ImageField
      source='emiratesData.mrzData.userPhoto'
      key='emiratesData.mrzData.userPhoto'
      label='User photo'
    />,
    <TextField
      label='Issuing Country'
      source='emiratesData.mrzData.issuing_country'
      key='emiratesData.mrzData.issuing_country'
    />
  ]
}
