import { useDataProvider } from 'react-admin'
import { EVENT_TYPE } from '../../constants'
import { differenceInDays, intervalToDuration, format, addDays } from 'date-fns'
import * as XLSX from 'xlsx-js-style'
import createWorksheetData from './createWorksheetData'

const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const useUserAccessCardEventsReport = () => {
  const dataProvider = useDataProvider()

  const formatDuration = (time) => {
    if (!time) return ''
    const durationSeconds = time / 1000
    const duration = intervalToDuration({ start: 0, end: durationSeconds * 1000 || 0 })
    return `${duration.hours.toString().padStart(2, '0')}:${
      duration.minutes.toString().padStart(2, '0')}:${duration.seconds.toString().padStart(2, '0')}`
  }

  const isWeekend = (date = new Date()) => {
    return date.getDay() === 6 || date.getDay() === 0
  }

  const getDataDates = (initialDate, isNextDay) => {
    const startDate = new Date(initialDate)
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    startDate.setMilliseconds(0)

    let endDate = new Date(initialDate)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)
    endDate.setMilliseconds(999)

    if (isNextDay) {
      endDate = addDays(endDate, 1)
    }

    return {
      startDate,
      endDate
    }
  }

  const getWorkHours = (initialDate, propertyBusinessHours, businessHoursProfileId) => {
    const weekday = WEEKDAYS[new Date(initialDate).getDay()]
    let from = '08:00'
    let to = '16:00'
    if (propertyBusinessHours) {
      const profile = propertyBusinessHours.profiles?.find(profile => profile.id === businessHoursProfileId)
      if (profile) {
        from = profile[weekday].timeIn
        to = profile[weekday].timeOut
      } else {
        from = propertyBusinessHours[weekday].timeIn
        to = propertyBusinessHours[weekday].timeOut
      }
    }
    const fromSplit = from.split(':')
    const toSplit = to.split(':')
    const timeIn = new Date(initialDate)
    timeIn.setHours(fromSplit[0])
    timeIn.setMinutes(fromSplit[1])
    timeIn.setSeconds(0)
    timeIn.setMilliseconds(0)
    let timeOut = new Date(initialDate)
    timeOut.setHours(toSplit[0])
    timeOut.setMinutes(toSplit[1])
    timeOut.setSeconds(0)
    timeOut.setMilliseconds(0)
    if (fromSplit[0] > toSplit[0]) {
      timeOut = addDays(timeOut, 1)
    }

    return {
      timeIn,
      timeOut,
      durationInMilliseconds: timeOut.getTime() - timeIn.getTime()
    }
  }

  const generateNewReport = async ({
    records,
    from,
    to,
    mobileUserId,
    propertyId
  }) => {
    const { data: mobileUser } = await dataProvider.getOne('mobileUsers', { id: mobileUserId })
    const { data: propertyData } = await dataProvider.getOne('properties', { id: propertyId })

    const days = differenceInDays(to, from)
    const data = []

    for (let i = 0; i <= days; i++) {
      const { startDate, endDate } = getDataDates(from.getTime() + i * 86400000, true)
      const { timeIn, timeOut, durationInMilliseconds } = getWorkHours(
        from.getTime() + i * 86400000,
        propertyData?.businessHours,
        mobileUser.businessHoursProfileId
      )

      records = records.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())

      const shiftAccessCardEvents = []
      const innerAccessCardEvents = records
        .filter(record =>
          (record.type === EVENT_TYPE.ACCESS_CARD_EVENT || record.type === EVENT_TYPE.ACCESS_CARD_LEAVE_EVENT) &&
          new Date(record.createdAt).getTime() >= timeIn.getTime() &&
          new Date(record.createdAt).getTime() <= timeOut.getTime()
        )

      if (
        innerAccessCardEvents.length === 0 ||
        innerAccessCardEvents[0].type === EVENT_TYPE.ACCESS_CARD_LEAVE_EVENT
      ) {
        const beforeAccessCardEvents = records
          .filter(record =>
            (record.type === EVENT_TYPE.ACCESS_CARD_EVENT || record.type === EVENT_TYPE.ACCESS_CARD_LEAVE_EVENT) &&
            new Date(record.createdAt).getTime() >= startDate.getTime() &&
            new Date(record.createdAt).getTime() < timeIn.getTime()
          )

        if (
          beforeAccessCardEvents.length > 0 &&
          beforeAccessCardEvents.at(-1).type === EVENT_TYPE.ACCESS_CARD_EVENT
        ) {
          shiftAccessCardEvents.push(beforeAccessCardEvents.at(-1))
        }
      }

      shiftAccessCardEvents.push(...innerAccessCardEvents)

      if (
        shiftAccessCardEvents.length > 0 &&
        shiftAccessCardEvents.at(-1).type === EVENT_TYPE.ACCESS_CARD_EVENT
      ) {
        const afterAccessCardEvents = records
          .filter(record =>
            (record.type === EVENT_TYPE.ACCESS_CARD_EVENT || record.type === EVENT_TYPE.ACCESS_CARD_LEAVE_EVENT) &&
            new Date(record.createdAt).getTime() >= timeOut.getTime() &&
            new Date(record.createdAt).getTime() < endDate.getTime()
          )
        if (
          afterAccessCardEvents.length > 0 &&
          afterAccessCardEvents[0].type === EVENT_TYPE.ACCESS_CARD_LEAVE_EVENT
        ) {
          shiftAccessCardEvents.push(afterAccessCardEvents[0])
        }
      }


      const firstAccessCardEntryEvent = shiftAccessCardEvents.filter(event => event.type === EVENT_TYPE.ACCESS_CARD_EVENT)?.[0]
      const accessCardLeaveEvents = shiftAccessCardEvents
        .filter(event => event.type === EVENT_TYPE.ACCESS_CARD_LEAVE_EVENT)
        .filter(event => {
          if (firstAccessCardEntryEvent) {
            return new Date(event.createdAt).getTime() > new Date(firstAccessCardEntryEvent.createdAt).getTime()
          }

          return true
        })
      const lastAccessCardLeaveEvent = accessCardLeaveEvents?.at(-1)

      const duration = accessCardLeaveEvents.length > 1
        ? formatDuration(accessCardLeaveEvents.reduce((accumulator, object) => {
            return accumulator + (!isNaN(object.duration) ? object.duration : 0)
          }, 0))
        : formatDuration(accessCardLeaveEvents?.[accessCardLeaveEvents?.length - 1]?.duration)

      data.push({
        date: format(new Date(from.getTime() + i * 86400000), 'dd MMM yyyy'),
        name: mobileUser.name,
        designation: mobileUser.designation,
        accessCard: mobileUser.accessCardId,
        timeIn: firstAccessCardEntryEvent?.createdAt
          ? format(new Date(firstAccessCardEntryEvent?.createdAt), 'HH:mm:ss')
          : '',
        timeOut: lastAccessCardLeaveEvent?.createdAt
          ? format(new Date(lastAccessCardLeaveEvent?.createdAt), 'HH:mm:ss')
          : '',
        duration,
        autoSignOut: lastAccessCardLeaveEvent?.autoSignOut || false,
        multipleDurations: accessCardLeaveEvents.length > 1 && duration !== '',
        notesDuration: firstAccessCardEntryEvent && lastAccessCardLeaveEvent &&
        lastAccessCardLeaveEvent.duration < durationInMilliseconds
          ? 'D'
          : '',
        notesLateIn:
          firstAccessCardEntryEvent &&
          new Date(firstAccessCardEntryEvent?.createdAt).getTime() > timeIn.getTime()
            ? 'L'
            : '',
        notesEarlyOut:
          lastAccessCardLeaveEvent &&
          new Date(lastAccessCardLeaveEvent?.createdAt).getTime() < timeOut.getTime()
            ? 'E'
            : '',
        notesAbsent: isWeekend(new Date(from.getTime() + i * 86400000)) ? 'W' : !firstAccessCardEntryEvent ? 'A' : ''
      })
    }

    downloadAsXLSXFile(data, mobileUser.name)
  }

  const downloadAsXLSXFile = (data, mobileUserName) => {

    /* Create worksheet */
    const worksheetData = createWorksheetData(mobileUserName, data)

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

    if (!worksheet['!merges']) {
      worksheet['!merges'] = []
    }
    worksheet['!merges'].push(XLSX.utils.decode_range('A1:H2'))
    worksheet['!merges'].push(XLSX.utils.decode_range('I1:L1'))

    const workbook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workbook, worksheet, mobileUserName)

    XLSX.writeFile(workbook, `${mobileUserName.replace(/ /g, '_').toLowerCase()}.xlsx`)
  }

  return {
    generateNewReport
  }
}

export default useUserAccessCardEventsReport
