import React, { useState, useCallback, useEffect } from 'react'
import {
  Create,
  CreateButton,
  Datagrid,
  DeleteButton,
  Filter,
  FormTab,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  ImageField,
  Toolbar,
  useListContext,
  useMutation,
  useNotify,
  TopToolbar,
  AutocompleteInput,
  DateField, useQuery, useRecordContext, EditButton
} from 'react-admin'

import debounce from 'lodash/debounce'
import { useSelector } from 'react-redux'

import SendIcon from '@material-ui/icons/Send'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckIcon from '@material-ui/icons/Check'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import client from '../../client/feathersClient'
import { EMobileUsersFlatsStatus } from '../../enums/mobileUsersFlats'
import LoadingButton from '../../elements/LoadingButton'
import validator from 'validator/es'
import ImportButton from '../../elements/ImportButton'
import UserManagementModal from './UserManagementModal'
import useUser from '../../hooks/useUser'
import { UserAccessCardEventsTab } from './tabs/UserAccessCardEventsTab'

const statuses = [
  {
    id: EMobileUsersFlatsStatus.PENDING_INVITATION,
    name: 'Pending invitation'
  },
  {
    id: EMobileUsersFlatsStatus.PENDING_APPROVAL,
    name: 'Pending approval'
  },
  {
    id: EMobileUsersFlatsStatus.DECLINED,
    name: 'Declined'
  },
  {
    id: EMobileUsersFlatsStatus.ACCEPTED,
    name: 'Accepted'
  }
]

export const validatePhone = () =>
  value => validator.isMobilePhone(value || '', 'any', { strictMode: true }) ? undefined : 'messages.validation.mobilePhone'

const ResendInviteButton = ({ record = {} }) => {
  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  let resendInvite = async () => {
    try {
      setLoading(true)
      await client.service('invitations').create({ mobileUsersFlatsId: record.id })
      notify('Resent invitation')
      setLoading(false)
    } catch (error) {
      console.error(error)
      notify('Cannot resent invitation for host')
      setLoading(false)
    }
  }

  resendInvite = useCallback(debounce(resendInvite, 300), [record, notify])

  const canResendInvite = record.status === EMobileUsersFlatsStatus.PENDING_INVITATION

  return canResendInvite
    ? (
      <LoadingButton label='Resend' onClick={resendInvite} loading={loading}>
        <SendIcon />
      </LoadingButton>
      )
    : null
}

const AcceptButton = ({ resource, record = {} }) => {
  const notify = useNotify()
  const [accept, { loading, error, data }] = useMutation({
    type: 'update',
    resource,
    payload: {
      id: record.id,
      data: {
        status: EMobileUsersFlatsStatus.ACCEPTED
      }
    }
  })

  const acceptHost = debounce(accept, 300)

  useEffect(() => {
    if (error?.message) {
      console.error(error)
      notify(error.message, 'warning')
    }
  }, [error, notify])

  useEffect(() => {
    if (data?.subscriptionExceeded) {
      notify('Sorry, you have reached the maximum number of users as per your license. Please contact your reseller.', 'warning')
    }
  }, [data, notify])

  const canAccept = record.status !== EMobileUsersFlatsStatus.ACCEPTED

  return canAccept
    ? (
      <LoadingButton label='Accept' onClick={acceptHost} loading={loading}>
        <CheckIcon />
      </LoadingButton>
      )
    : null
}

const DeclineButton = ({ resource, record = {} }) => {
  const [decline, { loading }] = useMutation({
    type: 'update',
    resource,
    payload: {
      id: record.id,
      data: {
        status: EMobileUsersFlatsStatus.DECLINED
      }
    }
  })
  const canDecline = record.status === EMobileUsersFlatsStatus.PENDING_APPROVAL
  const declineHost = debounce(decline, 300)

  return canDecline
    ? (
      <LoadingButton label='Decline' onClick={declineHost} loading={loading}>
        <CancelOutlinedIcon />
      </LoadingButton>
      )
    : null
}

const prepareData = (propertyId) => {
  const _propertyId = propertyId

  return async (data) => {
    if (data.unitNumber) {
      const flatQuery = await client.service('flats').find({
        query: {
          flatNumber: data.unitNumber,
          propertyId: _propertyId
        }
      })

      if (!flatQuery.data?.[0]?._id) {
        const createdFlat = await client.service('flats').create({
          flatNumber: data.unitNumber,
          propertyId: _propertyId
        })
        data.flatId = createdFlat._id
      } else {
        data.flatId = flatQuery.data?.[0]?._id
      }

      delete data.unitNumber
      return data
    }
    return null
  }
}

export const Filters = (props) => {
  const toRegex = (field) => {
    return searchText => {
      return searchText && {
        [`${field}.$regex`]: searchText,
        [`${field}.$options`]: 'i'
      }
    }
  }

  return (
    <Filter {...props}>
      <SelectInput
        source='status'
        choices={statuses}
        alwaysOn
      />
      <ReferenceInput
        source='flatId'
        label='Unit'
        reference='flats'
        alwaysOn
        sort={{ field: 'flatNumber', order: 'ASC' }}
        filterToQuery={(v) => toRegex('flatNumber')(v)}
        filter={{ propertyId: props.propertyId || undefined }}
      >
        <AutocompleteInput optionText='flatNumber' emptyText='clear search' />
      </ReferenceInput>
      <ReferenceInput
        source='mobileUserId'
        label='Phone'
        reference='mobileUsers'
        alwaysOn
        sort={{ field: 'phone', order: 'ASC' }}
        filterToQuery={(v) => toRegex('phone')(v)}
      >
        <AutocompleteInput optionText='phone' emptyText='clear search' />
      </ReferenceInput>
      <ReferenceInput
        source='mobileUserId'
        label='Name'
        reference='mobileUsers'
        alwaysOn
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
    </Filter>
  )
}

export const MobileUsersFlatsCreate = (props) => {
  const { propertyId } = useSelector(state => state.property)
  const toRegex = (field) => {
    return searchText => {
      return searchText && {
        [`${field}.$regex`]: searchText,
        [`${field}.$options`]: 'i'
      }
    }
  }

  return (
    <Create
      {...props}
      title='Create Host'
    >
      <SimpleForm>
        <TextInput
          source='phone'
          label='Phone'
          validate={[required(), validatePhone()]}
        />
        <TextInput
          source='name'
          label='Name'
          validate={required()}
        />
        <ReferenceInput
          source='flatId'
          label='Unit'
          reference='flats'
          sort={{ field: 'flatNumber', order: 'ASC' }}
          filterToQuery={(v) => toRegex('flatNumber')(v)}
          filter={{
            propertyId: propertyId || undefined,
            isArchived: {
              $ne: true
            }
          }}
          validate={required()}
          disabled={!propertyId}
        >
          <AutocompleteInput optionText='flatNumber' emptyText='clear search' />
        </ReferenceInput>
        <ReferenceInput
          source='propertyId'
          label='Property'
          reference='properties'
          validate={required()}
          defaultValue={propertyId}
          style={{
            display: 'none'
          }}
        >
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput
          source='email'
          label='Email'
        />
        <TextInput
          source='designation'
          label='Designation'
        />
      </SimpleForm>
    </Create>
  )
}

const MobileUsersFlatsShowToolbar = (props) => {
  return (
    <Toolbar {...props}>
      {props.type === 'propertyAdmins' && <DeleteButton />}
      <AcceptButton />
      <ResendInviteButton />
      <DeclineButton />
      {props.hostData && !props.hostData.isVerified && <EditButton style={{ marginLeft: 'auto' }} basePath='/mobileUsers' record={{ id: props.hostData._id }} label='Edit User' />}
    </Toolbar>
  )
}

export const MobileUsersFlatsShow = (props) => {
  const mobileUsersFlatId = props.id
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })
  const { data: mobileUsersFlatData } = useQuery({
    type: 'getOne',
    resource: 'mobileUsersFlats',
    enabled: !!mobileUsersFlatId,
    payload: { id: mobileUsersFlatId }
  })
  const { data: mobileUserData } = useQuery({
    type: 'getOne',
    resource: 'mobileUsers',
    enabled: !!mobileUsersFlatData?.mobileUserId,
    payload: { id: mobileUsersFlatData?.mobileUserId }
  })

  return (
    <>
      <Show
        {...props}
        title='Host'
      >
        <TabbedForm
          redirect={false}
          toolbar={<MobileUsersFlatsShowToolbar type={userData?.type} hostData={mobileUserData} />}
        >
          <FormTab label='summary'>
            <ReferenceField
              source='mobileUserId'
              label='Name'
              reference='mobileUsers'
              link={false}
            >
              <TextField source='name' />
            </ReferenceField>
            <ReferenceField
              source='flatId'
              label='Unit'
              reference='flats'
              link={false}
            >
              <TextField source='flatNumber' />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Phone'
              reference='mobileUsers'
              link={false}
            >
              <TextField source='phone' />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Email'
              reference='mobileUsers'
              link={false}
            >
              <TextField source='email' />
            </ReferenceField>
            <DateField source='createdAt' showTime />
            <SelectField
              source='status'
              label='Status'
              choices={statuses}
            />
          </FormTab>
          <FormTab path='show' label='ID'>
            <ReferenceField
              source='mobileUserId'
              label='Front'
              reference='mobileUsers'
              link={false}
            >
              <ImageField source='emiratesData.frontImage.publicUrl' />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Back'
              reference='mobileUsers'
              link={false}
            >
              <ImageField source='emiratesData.backImage.publicUrl' />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Document ID'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.document_number'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Name'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.given_names_readable'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Surname'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.surname'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Nationality'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.nationality'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Gender'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.sex'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='ID'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.optionals'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Expiration Date'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.expiration_date_readable'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='DOB'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.dob_readable'
              />
            </ReferenceField>
            <ReferenceField
              source='mobileUserId'
              label='Issuing Country'
              reference='mobileUsers'
              link={false}
            >
              <TextField
                source='emiratesData.mrzData.issuing_country'
              />
            </ReferenceField>
          </FormTab>
        </TabbedForm>
      </Show>
      {mobileUserData?.accessCardId && <UserAccessCardEventsTab accessCardId={mobileUserData.accessCardId} />}
    </>
  )
}

const MobileUsersFlatsListEmpty = (props) => {
  const { basePath } = useListContext()
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No hosts yet.
      </Typography>
      {props.type === 'propertyAdmins' && (<>
        <Typography variant='body1'>
          Do you want to add one?
        </Typography>
        <CreateButton style={{ marginTop: 30 }} variant='contained' basePath={basePath} />
      </>)}
    </Box>
  )
}

const ListActions = (props) => {
  return (
    <Box display='flex' justifyContent='center'>
      <ShowButton {...props} />
      <AcceptButton {...props} />
      <ResendInviteButton {...props} />
      <DeclineButton {...props} />
      {
        props?.type === 'propertyAdmins' && (<DeleteButton {...props} />)
      }
    </Box>
  )
}

const TopToolbarActions = props => {
  const { propertyId } = useSelector(state => state.property)

  const [openModal, setOpenModal] = useState(false)

  const { className, basePath, resource } = props
  return (
    <TopToolbar className={className}>
      {
        props?.type === 'propertyAdmins' && (<>
          <CreateButton basePath={basePath} />
          <UserManagementModal open={openModal} setOpen={setOpenModal}>
            <ImportButton
              filled
              additionalRequestData={{ propertyId }}
              resource={resource}
              prepareData={prepareData(propertyId)}
              setOpenModal={setOpenModal}
            />
          </UserManagementModal>
        </>)
      }
    </TopToolbar>
  )
}

export const MobileUsersFlatsList = (props) => {
  const { propertyId } = useSelector(state => state.property)
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })

  return (
    <List
      {...props}
      title='User management'
      exporter={false}
      empty={<MobileUsersFlatsListEmpty type={userData?.type} />}
      filters={propertyId && <Filters propertyId={propertyId} />}
      filter={{ propertyId: propertyId || undefined }}
      sort={{ field: 'status', order: 'ASC' }}
      actions={<TopToolbarActions type={userData?.type} />}
      bulkActionButtons={userData?.type === 'propertyAdmins'}
    >
      <Datagrid>
        <ReferenceField
          source='mobileUserId'
          label='Name'
          reference='mobileUsers'
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField
          source='flatId'
          label='Unit'
          reference='flats'
          link={false}
        >
          <TextField source='flatNumber' />
        </ReferenceField>
        <ReferenceField
          source='mobileUserId'
          label='Phone'
          reference='mobileUsers'
          link={false}
        >
          <TextField source='phone' />
        </ReferenceField>
        <SelectField
          source='status'
          label='Status'
          choices={statuses}
        />
        <ListActions type={userData?.type} />
      </Datagrid>
    </List>
  )
}
