import {
  AutocompleteInput,
  Datagrid, DateField, DateInput, downloadCSV, ExportButton, Filter,
  List, Pagination,
  ReferenceField, ReferenceInput, sanitizeListRestProps,
  SelectField,
  TextField, TextInput, TopToolbar, useGetOne, useListContext, useQuery
} from 'react-admin'
import { useParams } from 'react-router-dom'
import React, { cloneElement } from 'react'
import TimeField from '../../../elements/TimeField'
import { allEventTypeChoices } from '../../../constants'
import DurationField from '../../../elements/DurationField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { differenceInDays, format } from 'date-fns'
import jsonExport from 'jsonexport/dist'
import useUser from '../../../hooks/useUser'
import useUserAccessCardEventsReport from '../../../hooks/useUserAccessCardEventsReport'

const MAX_QUERY_ARRAY_LENGTH = 200

export const Filters = (props) => {
  const toRegex = (field) => {
    return searchText => {
      return searchText && {
        [`${field}.$regex`]: searchText,
        [`${field}.$options`]: 'i'
      }
    }
  }

  return (
    <Filter
      {...props}
      style={{
        marginTop: 0
      }}
    >
      <DateInput
        source='dateStart'
        label='From'
        allowEmpty
      />
      <DateInput
        source='dateEnd'
        label='To'
        allowEmpty
      />
      <ReferenceInput
        label='Property'
        source='propertyId'
        reference='properties'
        allowEmpty
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <TextInput
        source='message'
        label='Message'
      />
      <ReferenceInput
        label='Device'
        source='deviceId'
        reference='devices'
        allowEmpty
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
    </Filter>
  )
}

export const LogListEmpty = () => {
  return (
    <Box bgcolor='white' textAlign='center' color='black' p={3} pb={1} borderRadius={4} mt={10}>
      <Typography variant='inherit' paragraph>
        No logs available
      </Typography>
    </Box>
  )
}

const LogsPagination = props => <Pagination rowsPerPageOptions={[10, 25]} {...props} ActionsComponent={LogsPaginationActions} />

export const LogsPaginationActions = () => {
  const { page, perPage, total, setPage } = useListContext()
  const nbPages = Math.ceil(total / perPage) || 1
  return (
    nbPages > 1 && <Box display='flex' alignItems='center' ml={3}>
      <Button
        color='primary'
        disabled={page <= 1}
        {...page <= 1 && { style: { color: '#c5c5c5' } }}
        key='prev'
        onClick={() => setPage(page - 1)}
      >
        <ChevronLeft />
        Prev
      </Button>
      <Button
        color='primary'
        disabled={page === nbPages}
        {...page === nbPages && { style: { color: '#c5c5c5' } }}
        key='next'
        onClick={() => setPage(page + 1)}
      >
        Next
        <ChevronRight />
      </Button>
    </Box>
  )
}

const exporter = async (records, fetchRelatedRecords) => {
  let devices = {}
  let properties = {}
  const numbersOfRequests = Math.ceil(records.length / MAX_QUERY_ARRAY_LENGTH)
  const promises = []
  for (let i = 0; i < numbersOfRequests; i++) {
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(records.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH), 'propertyId', 'properties')
        .then((data) => {
          properties = { ...properties, ...data }
          resolve()
        })
    }))
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(records.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH), 'deviceId', 'devices')
        .then((data) => {
          devices = { ...devices, ...data }
          resolve()
        })
    }))
  }

  Promise.all(promises).then(() => {
    const data = records.map(record => ({
      date: format(new Date(record.date), 'dd/MM/yyyy'),
      time: format(new Date(record.date), 'HH:mm'),
      property: properties[record.propertyId]?.name,
      device: devices[record.deviceId]?.name,
      type: allEventTypeChoices.find(type => record.type === type.id).name,
      message: record.message
    }))

    const headers = ['date', 'time', 'property', 'device', 'type', 'duration', 'message']
    const rename = ['Date', 'Time', 'Property', 'Device', 'Type', 'Duration', 'Message']

    jsonExport(data, {
      headers,
      rename
    }, (err, csv) => {
      if (err) {
        console.error(err)
      }
      downloadCSV(csv, 'access_cards_logs_' + new Date().toISOString())
    })
  })
}

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total
  } = useListContext()
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  }, {
    enabled: Boolean(id)
  })
  const params = useParams()
  const { data: mobileUserFlat } = useGetOne('mobileUsersFlats', params.id)
  const { generateNewReport } = useUserAccessCardEventsReport()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
      {
        userData?.type === 'propertyAdmins' && (
          <ExportButton
            disabled={
              total === 0 ||
              !filterValues?.dateStart ||
              !filterValues?.dateEnd ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) > 92
              ) ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) < 0
              )
            }
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={150000}
            label='Export to CSV'
          />
        )
      }
      {
        userData?.type === 'propertyAdmins' && (
          <ExportButton
            size='small'
            disabled={
              total === 0 ||
              !filterValues?.dateStart ||
              !filterValues?.dateEnd ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) > 92
              ) ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) < 0
              )
            }
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={150000}
            label='Generate report'
            exporter={(records, fetchRelatedRecords) => generateNewReport({
              records,
              fetchRelatedRecords,
              from: new Date(filterValues?.dateStart),
              to: new Date(filterValues?.dateEnd),
              filters: filterValues,
              mobileUserId: mobileUserFlat?.mobileUserId,
              propertyId: mobileUserFlat?.propertyId
            })}
          />
        )
      }
    </TopToolbar>
  )
}

export const UserAccessCardEventsTab = (props) => {
  return (
    <Box style={{ marginTop: 48 }}>
      <Typography variant='h5'>Access Card Logs</Typography>
      <List
        basePath='logs'
        label={false}
        fullWidth
        filters={<Filters />}
        title=' '
        resource='logs'
        filter={{
          accessCardLogs: true,
          accessCardId: props.accessCardId
        }}
        bulkActionButtons={false}
        sort={{ field: 'date', order: 'DESC' }}
        pagination={<LogsPagination />}
        empty={<LogListEmpty />}
        exporter={(records, fetchRelatedRecords) => exporter(records, fetchRelatedRecords)}
        actions={<ListActions />}
      >
        <Datagrid>
          <DateField
            source='date'
            label='Date'
          />
          <TimeField
            source='date'
            label='Time'
          />
          <ReferenceField
            source='propertyId'
            label='Property'
            reference='properties'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField
            source='deviceId'
            reference='devices'
            basePath='devices'
            link={false}
            label='Device'
          >
            <TextField source='name' />
          </ReferenceField>
          <SelectField
            source='type'
            choices={allEventTypeChoices}
          />
          <DurationField
            sortable={false}
            source='duration'
            label='Duration'
          />
          <TextField
            source='message'
            label='Message'
          />
        </Datagrid>
      </List>
    </Box>
  )
}
