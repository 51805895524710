import Typography from '@material-ui/core/Typography'
import React from 'react'
import usePropertyDetails from '../hooks/usePropertyDetails'
import { CloudinaryContext, Image as CRImage } from 'cloudinary-react'
import { ESecurityLevel } from '../../../constants'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import useStyles from '../theme'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import BusinessHours from './BusinessHours'

const PropertyDetail = ({ name, value }) => {
  return (
    <Typography variant='p' component='div' style={{ marginBottom: 20, display: 'flex' }}>
      <span
        style={{
          width: '100%',
          maxWidth: 200,
          display: 'inline-block'
        }}
      >
        {name}:
      </span>
      <span>
        {value}
      </span>
    </Typography>
  )
}

const Image = (props) => {
  if (!props.publicUrl) {
    return (
      <span>No picture</span>
    )
  }
  return (
    <CloudinaryContext
      style={{
        display: 'inline-block'
      }}
      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
    >
      <CRImage width={200} publicId={props.publicUrl} />
    </CloudinaryContext>
  )
}

const PropertyDetails = () => {
  const classes = useStyles()
  const {
    data,
    extraField,
    setExtraField,
    extraFieldLabel,
    setExtraFieldLabel,
    extraFieldType,
    setExtraFieldType,
    extraFieldValuesList,
    setExtraFieldValuesList,
    secondExtraField,
    setSecondExtraField,
    secondExtraFieldLabel,
    setSecondExtraFieldLabel,
    secondExtraFieldType,
    setSecondExtraFieldType,
    secondExtraFieldValuesList,
    setSecondExtraFieldValuesList,
    businessHours,
    setWeekdayBusinessHours,
    createNewProfile,
    setProfileName,
    deleteProfile,
    lateCheckInNotification,
    setLateCheckInNotification,
    earlyCheckOutNotification,
    setEarlyCheckOutNotification,
    recordNFCVisitAsAccessCardVisit,
    setRecordNFCVisitAsAccessCardVisit,
    visitTimeLimit,
    setVisitTimeLimit,
    visitTimeLimitTimeoutInHours,
    setVisitTimeLimitTimeoutInHours,
    visitTimeLimitDeliveryTimeoutInMinutes,
    setVisitTimeLimitDeliveryTimeoutInMinutes,
    changeDetails,
    loading,
    userType
  } = usePropertyDetails()
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          variant='h5'
          component='h2'
          style={{
            marginBottom: 30
          }}
        >
          Property Details
        </Typography>
        <PropertyDetail name='Name' value={data?.name} />
        <PropertyDetail name='Address' value={data?.address} />
        <PropertyDetail name='Property Code' value={data?.refNumber} />
        <PropertyDetail name='Image Photo' value={<Image width={300} publicUrl={data?.photo?.publicUrl} />} />
        <PropertyDetail name='Property Logo' value={<Image width={300} publicUrl={data?.logo?.publicUrl} />} />
        <PropertyDetail name='Security Level' value={ESecurityLevel[data?.securityLevel ?? 'MID']} />
        {
          userType === 'propertyAdmins' &&
            <form noValidate>
              <Box display='block'>
                <FormControlLabel
                  control={<Switch />}
                  id='extraField'
                  name='extraField'
                  label='Enable Extra Field'
                  checked={extraField}
                  onChange={(e, val) => setExtraField(val)}
                />
              </Box>
              {extraField &&
                <>
                  <Box display='block'>
                    <TextField
                      variant='filled'
                      margin='normal'
                      id='extraFieldLabel'
                      label='Extra Field Label'
                      name='extraFieldLabel'
                      type='text'
                      value={extraFieldLabel}
                      className={classes.input}
                      onChange={e => setExtraFieldLabel(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box display='block'>
                    <TextField
                      select
                      variant='filled'
                      id='extraFieldType'
                      label='Extra Field Type'
                      name='extraFieldType'
                      type='text'
                      value={extraFieldType}
                      className={classes.input}
                      onChange={e => setExtraFieldType(e.target.value)}
                      defaultValue='text'
                      style={{ marginRight: 16 }}
                    >
                      <MenuItem value='text'>Text</MenuItem>
                      <MenuItem value='list'>List</MenuItem>
                    </TextField>
                    {extraFieldType === 'list' &&
                      <TextField
                        variant='filled'
                        id='extraFieldValuesList'
                        label='Extra Field List Options'
                        name='extraFieldValuesList'
                        type='text'
                        value={extraFieldValuesList}
                        className={classes.input}
                        onChange={e => setExtraFieldValuesList(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        helperText='Write list values separated by comma'
                      />
                    }
                  </Box>
                </>
              }
              <Box display='block'>
                <FormControlLabel
                  control={<Switch />}
                  id='secondExtraField'
                  name='secondExtraField'
                  label='Enable Second Extra Field'
                  checked={secondExtraField}
                  onChange={(e, val) => setSecondExtraField(val)}
                />
              </Box>
              {secondExtraField &&
                <>
                <Box display='block'>
                  <TextField
                    variant='filled'
                    margin='normal'
                    id='secondExtraFieldLabel'
                    label='Second Extra Field Label'
                    name='secondExtraFieldLabel'
                    type='text'
                    value={secondExtraFieldLabel}
                    className={classes.input}
                    onChange={e => setSecondExtraFieldLabel(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box display='block'>
                  <TextField
                    select
                    variant='filled'
                    id='secondExtraFieldType'
                    label='Extra Field Type'
                    name='secondExtraFieldType'
                    type='text'
                    value={secondExtraFieldType}
                    className={classes.input}
                    onChange={e => setSecondExtraFieldType(e.target.value)}
                    defaultValue='text'
                    style={{ marginRight: 16 }}
                  >
                    <MenuItem value='text'>Text</MenuItem>
                    <MenuItem value='list'>List</MenuItem>
                  </TextField>
                  {secondExtraFieldType === 'list' &&
                    <TextField
                      variant='filled'
                      id='secondExtraFieldValuesList'
                      label='Second Extra Field List Options'
                      name='secondExtraFieldValuesList'
                      type='text'
                      value={secondExtraFieldValuesList}
                      className={classes.input}
                      onChange={e => setSecondExtraFieldValuesList(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      helperText='Write list values separated by comma'
                    />
                  }
                </Box>
              </>}
              <Typography style={{ marginTop: 20 }}>
                Business Hours:
              </Typography>
              <BusinessHours
                createNewProfile={createNewProfile}
                businessHours={businessHours}
                setWeekdayBusinessHours={setWeekdayBusinessHours}
                setProfileName={setProfileName}
                deleteProfile={deleteProfile}
              />
              <Box display='block'>
                <FormControlLabel
                  control={<Switch />}
                  id='lateCheckInNotification'
                  name='lateCheckInNotification'
                  label='Enable Late Check-In Notification'
                  checked={lateCheckInNotification}
                  onChange={(e, val) => setLateCheckInNotification(val)}
                />
              </Box>
              <Box display='block'>
                <FormControlLabel
                  control={<Switch />}
                  id='earlyCheckOutNotification'
                  name='earlyCheckOutNotification'
                  label='Enable Early Check-Out Notification'
                  checked={earlyCheckOutNotification}
                  onChange={(e, val) => setEarlyCheckOutNotification(val)}
                />
              </Box>
              <Box style={{ marginTop: 20 }} display='block'>
                <FormControlLabel
                  control={<Switch />}
                  id='recordNFCVisitAsAccessCardVisit'
                  name='recordNFCVisitAsAccessCardVisit'
                  label='Record Buzzin App NFC visits (only people with Access Card and assigned unit) as Access Card Logs'
                  checked={recordNFCVisitAsAccessCardVisit}
                  onChange={(e, val) => setRecordNFCVisitAsAccessCardVisit(val)}
                />
              </Box>
              <Box style={{ marginTop: 20 }} display='block'>
                <FormControlLabel
                  control={<Switch />}
                  id='visitTimeLimit'
                  name='visitTimeLimit'
                  label='Enable Visit Time Limit'
                  checked={visitTimeLimit}
                  onChange={(e, val) => setVisitTimeLimit(val)}
                />
              </Box>
              {visitTimeLimit && (
                <Box display='block'>
                  <TextField
                    variant='filled'
                    margin='normal'
                    id='visitTimeLimitTimeoutInHours'
                    label='Visit Time Limit in Hours'
                    name='visitTimeLimitTimeoutInHours'
                    type='number'
                    value={visitTimeLimitTimeoutInHours}
                    className={classes.input}
                    onChange={e => setVisitTimeLimitTimeoutInHours(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              )}
              {visitTimeLimit && (
                <Box display='block'>
                  <TextField
                    variant='filled'
                    margin='normal'
                    id='visitTimeLimitDeliveryTimeoutInMinutes'
                    label='Delivery Visit Time Limit in Minutes'
                    name='visitTimeLimitDeliveryTimeoutInMinutes'
                    type='number'
                    value={visitTimeLimitDeliveryTimeoutInMinutes}
                    className={classes.input}
                    onChange={e => setVisitTimeLimitDeliveryTimeoutInMinutes(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              )}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={changeDetails}
                disabled={loading}
              >
                Save changes
              </Button>
            </form>
        }
      </CardContent>
    </Card>
  )
}

export default PropertyDetails
