import React, { useEffect, useState, cloneElement } from 'react'
import {
  List,
  TextField,
  Filter,
  DateField,
  DateTimeInput,
  ReferenceInput,
  TextInput,
  SelectInput,
  ReferenceField,
  SelectField,
  ShowButton,
  TabbedForm,
  Show,
  FunctionField,
  useShowController,
  useDataProvider,
  AutocompleteInput,
  Pagination,
  TopToolbar,
  useListContext,
  ExportButton,
  downloadCSV,
  sanitizeListRestProps, useQuery
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TimeField from '../../elements/TimeField'
import { eventTypeChoices } from '../../constants'
import { MobileUserDetailsTab } from './tabs/MobileUserDetailsTab'
import { GuestDetailsTab } from './tabs/GuestDetailsTab'
import { EventDetailsTab } from './tabs/EventDetailsTab'
import { Link } from 'react-router-dom'
import CustomizableDatagrid from '../../elements/CustomizableDatagrid'
import { format, differenceInDays } from 'date-fns'
import useUser from '../../hooks/useUser'
import DurationField from '../../elements/DurationField'

const MAX_QUERY_ARRAY_LENGTH = 200

export const Filters = (props) => {
  const { extraFieldLabel, secondExtraFieldLabel } = useSelector(state => state.property)

  const toRegex = (field) => {
    return searchText => {
      return searchText && {
        [`${field}.$regex`]: searchText,
        [`${field}.$options`]: 'i'
      }
    }
  }

  return (
    <Filter
      {...props}
      style={{
        marginTop: 0
      }}
    >
      <DateTimeInput
        source='dateStart'
        label='From'
        allowEmpty
      />
      <DateTimeInput
        source='dateEnd'
        label='To'
        allowEmpty
      />
      <SelectInput
        label='Type'
        source='type'
        choices={eventTypeChoices}
      />
      <TextInput
        source='message'
        label='Message'
      />
      {extraFieldLabel && (
        <TextInput
          source='extraField'
          label={extraFieldLabel}
        />
      )}
      {secondExtraFieldLabel && (
        <TextInput
          source='secondExtraField'
          label={secondExtraFieldLabel}
        />
      )}
      <ReferenceInput
        label='Device'
        source='deviceId'
        reference='devices'
        allowEmpty
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <ReferenceInput
        label='Unit'
        source='flatId'
        reference='flats'
        allowEmpty
        sort={{ field: 'flatNumber', order: 'ASC' }}
        filterToQuery={(v) => toRegex('flatNumber')(v)}
        filter={{ propertyId: props.propertyId || undefined }}
      >
        <AutocompleteInput optionText='flatNumber' emptyText='clear search' />
      </ReferenceInput>
      <ReferenceInput
        label='Host'
        source='hostId'
        reference='mobileUsers'
        allowEmpty
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(v) => toRegex('name')(v)}
      >
        <AutocompleteInput optionText='name' emptyText='clear search' />
      </ReferenceInput>
      <TextInput
        source='guestName'
        allowEmpty
        label='Guest Name'
      />
      <TextInput
        source='guestMobileNumber'
        label='Guest Mobile Number'
        allowEmpty
      />
      <TextInput
        source='delivererMobileNumber'
        label='Deliverer Mobile Number'
        allowEmpty
      />
      <TextInput
        source='delivererName'
        label='Deliverer Name'
        allowEmpty
      />
      <TextInput
        source='documentNumber'
        label='Document ID'
        allowEmpty
      />
      <TextInput
        source='documentOptionals'
        label='ID'
        allowEmpty
      />
    </Filter>
  )
}

export const LogListEmpty = () => {
  return (
    <Box textAlign='center' m={4}>
      <Typography variant='h4' paragraph>
        No logs available
      </Typography>
    </Box>
  )
}

const HostName = (props) => {
  const dataProvider = useDataProvider()
  const [flat, setFlat] = useState(null)

  useEffect(() => {
    dataProvider.getOne('flats', { id: props.record?.flatId })
      .then(({ data }) => {
        if (data.isPreset) setFlat(data)
      })
      .catch(() => {
        setFlat(null)
      })
  }, [props.record.flatId, dataProvider])

  if (flat) {
    return (
      <ReferenceField
        {...props}
        source='flatId'
        reference='flats'
      >
        <TextField source='flatNumber' />
      </ReferenceField>
    )
  }
  return (
    <ReferenceField
      {...props}
    >
      <TextField source='name' />
    </ReferenceField>
  )
}

export const LogsPaginationActions = () => {
  const { page, perPage, total, setPage } = useListContext()
  const nbPages = Math.ceil(total / perPage) || 1
  return (
    nbPages > 1 && <Box display='flex' alignItems='center' ml={3}>
      <Button
        color='primary'
        disabled={page <= 1}
        {...page <= 1 && { style: { color: '#c5c5c5' } }}
        key='prev'
        onClick={() => setPage(page - 1)}
      >
        <ChevronLeft />
        Prev
      </Button>
      <Button
        color='primary'
        disabled={page === nbPages}
        {...page === nbPages && { style: { color: '#c5c5c5' } }}
        key='next'
        onClick={() => setPage(page + 1)}
      >
        Next
        <ChevronRight />
      </Button>
    </Box>
  )
}

const LogsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} ActionsComponent={LogsPaginationActions} />

const exporter = async (records, fetchRelatedRecords, propertyId, extraFieldLabel, secondExtraFieldLabel) => {
  let hosts = {}
  let flats = {}
  const numbersOfRequests = Math.ceil(records.length / MAX_QUERY_ARRAY_LENGTH)
  const promises = []
  for (let i = 0; i < numbersOfRequests; i++) {
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(records.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH), 'hostId', 'mobileUsers')
        .then((data) => {
          hosts = { ...hosts, ...data }
          resolve()
        })
    }))
    promises.push(new Promise((resolve) => {
      fetchRelatedRecords(records.slice(i * MAX_QUERY_ARRAY_LENGTH, (i + 1) * MAX_QUERY_ARRAY_LENGTH), 'flatId', 'flats')
        .then((data) => {
          flats = { ...flats, ...data }
          resolve()
        })
    }))
  }

  Promise.all(promises).then(() => {
    const data = records.filter(record => record.propertyId === propertyId).map(record => ({
      date: format(new Date(record.date), 'dd/MM/yyyy'),
      time: format(new Date(record.date), 'HH:mm'),
      host: record.hostId
        ? hosts[record.hostId]?.name
        : (record.flatId && flats[record.flatId]?.isPreset)
            ? flats[record.flatId]?.flatNumber
            : '',
      guestName: record.guestName,
      guestMobileNumber: record.guestMobileNumber,
      delivererName: record.delivererName,
      delivererMobileNumber: record.delivererMobileNumber,
      unitNumber: record.flatId ? flats[record.flatId]?.flatNumber : '',
      type: eventTypeChoices.find(type => record.type === type.id)?.name,
      id: record.emiratesData?.mrzData?.optionals,
      nationality: record.emiratesData?.mrzData?.nationality,
      message: record.message,
      ...extraFieldLabel && {
        extraField: record.extraField
      },
      ...secondExtraFieldLabel && {
        secondExtraField: record.secondExtraField
      }
    }))

    const headers = ['date', 'time', 'host', 'guestName', 'guestMobileNumber', 'delivererName', 'delivererMobileNumber', 'unitNumber', 'type', 'message', 'id', 'nationality']
    if (extraFieldLabel) {
      headers.push('extraField')
    }
    if (secondExtraFieldLabel) {
      headers.push('secondExtraField')
    }

    const rename = ['Date', 'Time', 'Host', 'Guest name', 'Guest Mobile Number', 'Deliverer name', 'Deliverer Mobile Number', 'Unit number', 'Type', 'Message', 'ID', 'Nationality']
    if (extraFieldLabel) {
      rename.push(extraFieldLabel)
    }
    if (secondExtraFieldLabel) {
      rename.push(secondExtraFieldLabel)
    }

    jsonExport(data, {
      headers,
      rename
    }, (err, csv) => {
      if (err) {
        console.error(err)
      }
      downloadCSV(csv, 'logs_' + new Date().toISOString())
    })
  })
}

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
    setFilters
  } = useListContext()
  const { propertyId } = useSelector(state => state.property)
  const { id } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })
  filterValues.$select = '_id'

  useEffect(() => {
    setFilters({ propertyId }, displayedFilters)
  }, [propertyId])

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
      {
        userData?.type === 'propertyAdmins' && (
          <ExportButton
            disabled={
              total === 0 ||
              !filterValues?.dateStart ||
              !filterValues?.dateEnd ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) > 92
              ) ||
              (
                filterValues?.dateStart &&
                filterValues?.dateEnd &&
                differenceInDays(new Date(filterValues?.dateEnd), new Date(filterValues?.dateStart)) < 0
              )
            }
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={200000}
            label='Export to CSV'
          />
        )
      }
    </TopToolbar>
  )
}

export const LogView = (props) => {
  const { propertyId, extraFieldLabel, secondExtraFieldLabel } = useSelector(state => state.property)

  return (
    <List
      {...props}
      pagination={<LogsPagination />}
      empty={<LogListEmpty />}
      title='Logs'
      filters={propertyId && <Filters propertyId={propertyId} />}
      bulkActionButtons={false}
      filter={{
        propertyId: propertyId || undefined,
        $select: ['date', 'type', 'hostId', 'deviceId', 'propertyId', 'flatId', 'guestName', 'guestMobileNumber', 'delivererName', 'delivererMobileNumber', 'message', 'duration', 'extraField', 'secondExtraField', 'emiratesData.mrzData.document_number', 'emiratesData.mrzData.optionals', 'emiratesData.mrzData.nationality']
      }}
      resource='logs'
      sort={{ field: 'date', order: 'DESC' }}
      exporter={(records, fetchRelatedRecords) => exporter(records, fetchRelatedRecords, propertyId, extraFieldLabel, secondExtraFieldLabel)}
      actions={<ListActions />}
    >
      <CustomizableDatagrid defaultColumns={['date', 'guestName', 'delivererName', 'flatId', 'deviceId', 'hostId', 'type', 'duration', 'message', 'extraField', 'secondExtraField']}>
        <DateField
          source='date'
          label='Date'
        />
        <TimeField
          source='date'
          label='Time'
        />
        <FunctionField
          label='Guest Name'
          source='guestName'
          render={record => {
            const guestLink = record?.guestId ? 'guest-details' : '1'
            return (<Link style={{ textDecoration: 'none' }} to={`events/${record._id}/show/${guestLink}`}><Typography style={{ textDecoration: 'none' }} color='primary' variant='body2'>{record.guestName}</Typography></Link>)
          }}
        />
        <FunctionField
          label='Guest Mobile Number'
          source='guestMobileNumber'
          render={record => {
            const guestLink = record?.guestId ? 'guest-details' : '1'
            return (<Link style={{ textDecoration: 'none' }} to={`events/${record._id}/show/${guestLink}`}><Typography style={{ textDecoration: 'none' }} color='primary' variant='body2'>{record.guestMobileNumber}</Typography></Link>)
          }}
        />
        <FunctionField
          label='Deliverer Mobile Number'
          source='delivererMobileNumber'
          render={record => {
            const guestLink = record?.delivererId ? 'guest-details' : '1'
            return (<Link style={{ textDecoration: 'none' }} to={`events/${record._id}/show/${guestLink}`}><Typography style={{ textDecoration: 'none' }} color='primary' variant='body2'>{record.delivererMobileNumber}</Typography></Link>)
          }}
        />
        <FunctionField
          label='Deliverer Name'
          source='delivererName'
          render={record => {
            const guestLink = record?.delivererId ? 'guest-details' : '1'
            return (<Link style={{ textDecoration: 'none' }} to={`events/${record._id}/show/${guestLink}`}><Typography style={{ textDecoration: 'none' }} color='primary' variant='body2'>{record.delivererName}</Typography></Link>)
          }}
        />
        <HostName
          source='hostId'
          reference='mobileUsers'
          link='show'
          label='Host'
        />
        <ReferenceField
          source='flatId'
          label='Unit Number'
          reference='flats'
        >
          <TextField source='flatNumber' />
        </ReferenceField>
        <ReferenceField
          source='deviceId'
          reference='devices'
          basePath='devices'
          link='show'
          label='Device'
        >
          <TextField source='name' />
        </ReferenceField>
        <SelectField
          source='type'
          choices={eventTypeChoices}
        />
        <DurationField
          sortable={false}
          source='duration'
          label='Duration'
        />
        <TextField
          source='message'
          label='Message'
        />
        <TextField
          label='Document ID'
          source='emiratesData.mrzData.document_number'
          key='emiratesData.mrzData.document_number'
        />
        <TextField
          label='ID'
          source='emiratesData.mrzData.optionals'
          key='emiratesData.mrzData.optionals'
        />
        <TextField
          label='Nationality'
          source='emiratesData.mrzData.nationality'
          key='emiratesData.mrzData.nationality'
        />
        {extraFieldLabel ? <TextField source='extraField' label={extraFieldLabel} /> : null}
        {secondExtraFieldLabel ? <TextField source='secondExtraField' label={secondExtraFieldLabel} /> : null}
        <ShowButton {...props} />
      </CustomizableDatagrid>
    </List>
  )
}

export const LogShow = (props) => {
  const { record } = useShowController(props)

  return (
    <Show
      {...props}
      title='Host'
    >
      <TabbedForm
        toolbar={false}
        redirect={false}
      >
        <EventDetailsTab {...props} />
        {record?.guestId
          ? <MobileUserDetailsTab mobileUserSource='guestId' path='guest-details' label='Guest details' {...props} />
          : record?.delivererId
            ? <MobileUserDetailsTab mobileUserSource='delivererId' path='guest-details' label='Deliverer details' {...props} />
            : <GuestDetailsTab {...props} />}

        <MobileUserDetailsTab
          mobileUserSource='hostId'
          path='host-details'
          label='Host details'
          {...props}
        />
      </TabbedForm>
    </Show>
  )
}
