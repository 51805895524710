import moment from 'moment'

const createWorksheetData = (name, data) => {
  return [
    [
      {
        v: 'Disclaimer:',
        s: {
          font: {
            bold: true,
            italic: true
          },
          alignment: {
            vertical: 'top',
            horizontal: 'right'
          }
        }
      },
      {
        v: 'Please note that only visitors or staff who checked-in using Buzzin VMS will be reported in this list. Visitors or staff who left the premises without checking-out using Buzzin VMS will still be reported in this list.',
        s: {
          font: {
            italic: true
          },
          alignment: {
            vertical: 'top',
            horizontal: 'left',
            wrapText: true
          }
        }
      }
    ],
    [''],
    [''],
    ['Report Time: ' + moment().format('HH:mm')],
    ['Date: ' + moment().format('MMMM Do YYYY')],
    [
      {
        v: name,
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true,
            color: {
              rgb: '4472c4'
            }
          },
          border: {
            top: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'medium' }
          }
        }
      }, '', '', '', '', {
        v: '',
        s: {
          border: {
            right: { style: 'medium' }
          }
        }
      }
    ],
    [''],
    [
      {
        v: '#',
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true
          },
          border: {
            top: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Time',
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true
          },
          border: {
            top: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Name',
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true
          },
          border: {
            top: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Unit number',
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true
          },
          border: {
            top: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Phone',
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true
          },
          border: {
            top: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Designation',
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true
          },
          border: {
            top: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'medium' }
          }
        }
      },
      {
        v: 'Access Card',
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          font: {
            bold: true
          },
          border: {
            top: { style: 'medium' },
            right: { style: 'medium' },
            bottom: { style: 'medium' }
          }
        }
      }
    ],
    ...data.map((row, index) => [
      {
        v: index + 1,
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          border: {
            left: { style: 'medium' },
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.time,
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.name,
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.unitNumber,
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.phone,
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.designation,
        s: {
          border: {
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
        }
      },
      {
        v: row.isAccessCard,
        s: {
          alignment: {
            vertical: 'center',
            horizontal: 'center'
          },
          border: {
            right: { style: 'medium' },
            bottom: { style: 'thin' }
          }
        }
      }
    ])
  ]
}

export default createWorksheetData
