import { useMutation, useNotify, useQuery } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import useUser from '../../../hooks/useUser'

const BUSINESS_HOURS = {
  monday: {
    isActive: true,
    timeIn: '08:00',
    timeOut: '16:00'
  },
  tuesday: {
    isActive: true,
    timeIn: '08:00',
    timeOut: '16:00'
  },
  wednesday: {
    isActive: true,
    timeIn: '08:00',
    timeOut: '16:00'
  },
  thursday: {
    isActive: true,
    timeIn: '08:00',
    timeOut: '16:00'
  },
  friday: {
    isActive: true,
    timeIn: '08:00',
    timeOut: '16:00'
  },
  saturday: {
    isActive: true,
    timeIn: '08:00',
    timeOut: '16:00'
  },
  sunday: {
    isActive: true,
    timeIn: '08:00',
    timeOut: '16:00'
  }
}

const usePropertyDetails = () => {
  const notify = useNotify()
  const {
    id
  } = useUser()
  const { data: userData } = useQuery({
    type: 'getOne',
    resource: 'users',
    payload: { id }
  })
  const { propertyId } = useSelector(state => state.property)
  const [extraField, setExtraField] = useState(false)
  const [extraFieldLabel, setExtraFieldLabel] = useState('')
  const [extraFieldType, setExtraFieldType] = useState('text')
  const [extraFieldValuesList, setExtraFieldValuesList] = useState('')
  const [secondExtraField, setSecondExtraField] = useState(false)
  const [secondExtraFieldLabel, setSecondExtraFieldLabel] = useState('')
  const [secondExtraFieldType, setSecondExtraFieldType] = useState('text')
  const [secondExtraFieldValuesList, setSecondExtraFieldValuesList] = useState('')
  const [businessHours, setBusinessHours] = useState(BUSINESS_HOURS)
  const [lateCheckInNotification, setLateCheckInNotification] = useState(false)
  const [earlyCheckOutNotification, setEarlyCheckOutNotification] = useState(false)
  const [recordNFCVisitAsAccessCardVisit, setRecordNFCVisitAsAccessCardVisit] = useState(false)
  const [visitTimeLimit, setVisitTimeLimit] = useState(false)
  const [visitTimeLimitTimeoutInHours, setVisitTimeLimitTimeoutInHours] = useState(null)
  const [visitTimeLimitDeliveryTimeoutInMinutes, setVisitTimeLimitDeliveryTimeoutInMinutes] = useState(null)
  const dispatch = useDispatch()
  const { data } = useQuery({
    type: 'getOne',
    resource: 'properties',
    payload: { id: propertyId }
  }, {
    onSuccess: ({ data }) => {
      setExtraField(data?.extraField)
      setExtraFieldLabel(data?.extraFieldLabel)
      setExtraFieldType(data?.extraFieldType)
      setExtraFieldValuesList(data?.extraFieldValuesList)
      setSecondExtraField(data?.secondExtraField)
      setSecondExtraFieldLabel(data?.secondExtraFieldLabel)
      setSecondExtraFieldType(data?.secondExtraFieldType)
      setSecondExtraFieldValuesList(data?.secondExtraFieldValuesList)
      setLateCheckInNotification(data?.lateCheckInNotification)
      setEarlyCheckOutNotification(data?.earlyCheckOutNotification)
      setRecordNFCVisitAsAccessCardVisit(data?.recordNFCVisitAsAccessCardVisit)
      setVisitTimeLimit(data?.visitTimeLimit)
      setVisitTimeLimitTimeoutInHours(data?.visitTimeLimitTimeoutInHours)
      setVisitTimeLimitDeliveryTimeoutInMinutes(data?.visitTimeLimitDeliveryTimeoutInMinutes)

      if (data?.businessHours) {
        setBusinessHours(data?.businessHours)
      }
    }
  })
  const [save, { loading }] = useMutation({
    type: 'update',
    resource: 'properties',
    payload: {
      id: propertyId,
      data: {
        extraField,
        extraFieldLabel,
        extraFieldType,
        extraFieldValuesList,
        secondExtraField,
        secondExtraFieldLabel,
        secondExtraFieldType,
        secondExtraFieldValuesList,
        businessHours,
        lateCheckInNotification,
        earlyCheckOutNotification,
        recordNFCVisitAsAccessCardVisit,
        visitTimeLimit,
        visitTimeLimitTimeoutInHours,
        visitTimeLimitDeliveryTimeoutInMinutes,
      }
    }
  }, {
    onSuccess: () => {
      dispatch({ type: 'PROPERTY_SET', property: propertyId, extraFieldLabel, setSecondExtraFieldLabel })
    }
  })

  const changeDetails = async (event) => {
    event.preventDefault()
    save()
    notify('messages.settings.companyDetailsSave')
  }

  const setWeekdayBusinessHours = (weekday, key, value, profile) => {
    const newBusinessHours = JSON.parse(JSON.stringify(businessHours))
    if (profile === -1) {
      newBusinessHours[weekday][key] = value
    } else {
      newBusinessHours.profiles[profile][weekday][key] = value
    }
    setBusinessHours(newBusinessHours)
  }

  const createNewProfile = () => {
    const newBusinessHours = JSON.parse(JSON.stringify(businessHours))
    if (!newBusinessHours.hasOwnProperty('profiles')) {
      newBusinessHours.profiles = []
    }
    newBusinessHours.profiles.push({
      ...BUSINESS_HOURS,
      name: `Profile ${businessHours.profiles?.length + 1 || 1}`,
      id: Math.random().toString(36).slice(2)
    })
    setBusinessHours(newBusinessHours)
  }

  const setProfileName = (index, name) => {
    const newBusinessHours = JSON.parse(JSON.stringify(businessHours))
    newBusinessHours.profiles[index].name = name
    setBusinessHours(newBusinessHours)
  }

  const deleteProfile = (index) => {
    const newBusinessHours = JSON.parse(JSON.stringify(businessHours))
    newBusinessHours.profiles.splice(index, 1)
    setBusinessHours(newBusinessHours)
  }

  return {
    data,
    extraField,
    setExtraField,
    extraFieldLabel,
    setExtraFieldLabel,
    extraFieldType,
    setExtraFieldType,
    extraFieldValuesList,
    setExtraFieldValuesList,
    secondExtraField,
    setSecondExtraField,
    secondExtraFieldLabel,
    setSecondExtraFieldLabel,
    secondExtraFieldType,
    setSecondExtraFieldType,
    secondExtraFieldValuesList,
    setSecondExtraFieldValuesList,
    businessHours,
    setWeekdayBusinessHours,
    createNewProfile,
    setProfileName,
    deleteProfile,
    lateCheckInNotification,
    setLateCheckInNotification,
    earlyCheckOutNotification,
    setEarlyCheckOutNotification,
    recordNFCVisitAsAccessCardVisit,
    setRecordNFCVisitAsAccessCardVisit,
    visitTimeLimit,
    setVisitTimeLimit,
    visitTimeLimitTimeoutInHours,
    setVisitTimeLimitTimeoutInHours,
    visitTimeLimitDeliveryTimeoutInMinutes,
    setVisitTimeLimitDeliveryTimeoutInMinutes,
    loading,
    changeDetails,
    userType: userData?.type
  }
}

export default usePropertyDetails
