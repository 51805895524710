import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import moment from 'moment'

const useStyles = makeStyles({
  table: {
    minWidth: 320
  }
})

export default function VisitorsInBuildingTable ({ eventsData }) {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableRow>
          <TableCell>{translate('messages.dashboard.hostDetails')}</TableCell>
          <TableCell>{translate('messages.dashboard.information')}</TableCell>
          <TableCell>{translate('messages.dashboard.guestPhoneNumber')}</TableCell>
          <TableCell>{translate('messages.dashboard.entryType')}</TableCell>
          <TableCell>{translate('messages.dashboard.date')}</TableCell>
        </TableRow>
        <TableBody>
          {eventsData && eventsData.map((row) => {
            return (
              <TableRow key={row.guestMobileNumber || row.delivererMobileNumber}>
                <TableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    to={`/mobileUsers/${row.hostId}/show`}
                  >
                    {translate('messages.dashboard.hostDetails')}
                  </Button>
                </TableCell>
                <TableCell>{row.message}</TableCell>
                <TableCell>{row.guestMobileNumber || row.delivererMobileNumber}</TableCell>
                <TableCell>{
                  row.type === 'internalAccessCardEvents'
                    ? 'Internal'
                    : row.type === 'deliveryEvents'
                      ? 'Delivery'
                      : row.entryType && row.entryType?.charAt(0).toUpperCase() + row.entryType?.slice(1) === 'Eid'
                        ? 'ID'
                        : row.entryType?.charAt(0).toUpperCase() + row.entryType?.slice(1)
                }
                </TableCell>
                <TableCell>{moment(row.date).local().format('DD-MM-YYYY HH:mm')}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
